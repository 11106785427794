/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.keyword = ''
    }
  }
}
export default searchFrom
