<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row style="display:flex;align-items:center">
          <el-col :span="6">
            <div class="input-wrapper">
              <el-input @keyup.enter.native="searchEvent" placeholder="客户手机号/姓名/关键字" v-model="form.keyword"></el-input>
            </div>
          </el-col>
          <el-col :span="18" style="text-align:right">
            <!-- <el-button @click="changeAddDialog(true)" v-if="buttonAuth.includes('venueOperation:menber:add')" class="btn-round-style add-btn">{{$t('add')}}</el-button> -->
            <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
            <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight - 20"
				style="width: 100%"
        border
        @sort-change='sort_post_change'
        :row-class-name="tableRowClassName">
				<el-table-column
					prop="memberName"
          align="center"
          show-overflow-tooltip
          min-width="150"
					label="客户名称">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center; justify-content: center;">
              <img :src="scope.row.faceImgUrl ? scope.row.faceImgUrl : profilePhoto" @click="previewImg(scope.row.faceImgUrl)" style="height:50px; width: 50px; float:left" alt="">
              <div class="member-text">
                <p>{{scope.row.memberName}}</p>
                <p>{{scope.row.telephone}}</p>
              </div>
            </div>
          </template>
				</el-table-column>
				<el-table-column
					prop="idCardNo"
          align="center"
          min-width="120"
          show-overflow-tooltip
					label="身份证号">
				</el-table-column>
        <el-table-column
          align="center"
					prop="remark"
          min-width="100px"
					label="备注">
				</el-table-column>
        <el-table-column
          align="center"
					:label="$t('option')"
          width="200px">
          <template slot-scope="scope">
            <el-button v-if="buttonAuth.includes('venueOperation:menber:update')" @click="changeAddDialog(true, true, scope.row)" type="text">编辑</el-button>
            <el-button @click="changeActivateCardDialog(scope.row)" type="text">开卡</el-button>
            <!-- <el-button v-if="buttonAuth.includes('member:view')" @click="openDetailDialog(scope.row)" type="text" size="small">消费记录</el-button> -->
            <el-button @click="goToOrder(scope.row)" type="text">开单</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <div class="page-contain">
      <div>
        <el-button style="float:left;" class="default-button" @click="changeAddDialog(true)" v-if="buttonAuth.includes('venueOperation:menber:add')">{{$t('add')}}</el-button>
        <el-button style="float:left;" class="default-button" @click="downFile" v-if="buttonAuth.includes('venueOperation:member:template')">下载导入模板</el-button>
        <el-upload
          v-if="buttonAuth.includes('venueOperation:member:import')"
          class="upload"
          style="float:left;margin-left:10px"
          :show-file-list="false"
          :with-credentials="true"
          :action="apis.importMemberPersonalInfo"
          :httpRequest="uploadHttpDefault"
          name="file"
          :limit="999"
          :data="{
            merchantId: merchantAccountDetailInfoDTO.merchantId,
            merchantName: merchantAccountDetailInfoDTO.merchantName,
          }"
          :on-success="uploadSuccess"
          :on-error="uploadError"
        >
          <el-button class="default-button">导入</el-button>
        </el-upload>
        <el-button
          v-if="buttonAuth.includes('venueOperation:member:export')"
          style="float:left;margin-left:10px"
          class="default-button"
          @click="memberPersonInfoExport(apis.memberPersonInfoExport, {
            merchantId: merchantAccountDetailInfoDTO.merchantId
          })"
          >导出</el-button
        >
      </div>
    </div>
    <!-- 图片预览 -->
    <preview-image :src="previewImage.src" :show="previewImage.visible" @closeDialog="() => {this.previewImage.visible=false}"></preview-image>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
   <!-- 消费记录弹窗 -->
    <DetailDialog ref="detailDialog" :show="detailDialog.visible" @closeDialog="changeDetailDialog"></DetailDialog>
    <!-- 修改储值卡弹窗 -->
    <editDialog ref="editDialog" :show="editDialog.visible" @closeDialog="target"></editDialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import apis from '@/apis'
import download from '@/mixins/download'
import mixin from '@/mixins/index'
import addDialog from './dialog/addDialog.vue'
import DetailDialog from './dialog/detailDialog.vue'
import OrderRecordSearchClass from './orderRecordSearchClass'
// import { isNull } from "lodash";
import profilePhoto from '../../../assets/images/profilePhoto.jpg'
import editDialog from './dialog/editDialog.vue'
import previewImage from "@/components/previewImage.vue";
// import dataDialogVue from '../../system/role/dialog/dataDialog.vue'

export default {
  components: {
    addDialog,
    DetailDialog,
    editDialog,
    previewImage,
  },
  mixins: [mixin, download],
  data() {
    return {
      form: new OrderRecordSearchClass('form'), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      detailDialog: {
        visible: false,
      },
      editDialog: {
        visible: false,
      },
      profilePhoto,
      pageNum: 1,
      previewImage: {
        visible: false,
        src: "",
      },
    }
  },
  computed: {
    ...mapState(['Language', 'TOKEN', 'venueId', 'merchantAccountDetailInfoDTO']),
  },
  activated() {
    // 设置会员卡种
    // const params = {
    //   venueId: this.venueId,
    // }
    const cardList = [{
      id: "0",
      cardType: "0",
      cardName: "无",
    }]
    this.setCardList(cardList)
    this.setPureCardList([])
    // this.$http.get(apis.getCardInfo, { params }).then((res) => {
    //   if (res.data.code === 0) {
    //     const pureCardList = (res.data && res.data.rows) || [];
    //     this.setPureCardList(pureCardList)
    //     cardList = cardList.concat(pureCardList)
    //     this.setCardList(cardList)
    //   }
    // })
  },
  methods: {
    ...mapMutations(['setCardList', 'setPureCardList']),
    // 清空按钮
    cleanForm() {
      this.form = new OrderRecordSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.queryMemberList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
          this.pageNum = res.data.pageNum
        }
      })
    },
    /**
     * @function 页面跳转到开单
     * @param {Object} row 页面表格行数据
     */
    goToOrder(row) {
      this.$router.push({
          name: 'order',
          params: {
            type: '1',
            customerPhone: (row && row.telephone) || "",
          },
        })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          this.$refs['addDialog'].getDataQuery(JSON.parse(JSON.stringify(data)))
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.form.pageNum = this.pageNum
        this.searchData()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
     /**
     * @function 当开卡弹窗点击确定的时候触发
     */
    changeActivateCardDialog(data) {
      console.log(data)
      this.$router.push({
        name: "menberCard",
        params: {
          datas: data,
          userId: data.userId,
          telephone: data.telephone,
        },
      })
    },
    /**
     * @function 打开消费记录弹窗
     * @param {Object} data 选中的数据
     */
    openDetailDialog(data) {
      this.detailDialog.visible = true
      this.$refs.detailDialog.getDataQuery(data)
    },
    /**
     * @function 关闭消费记录弹窗
     */
    changeDetailDialog() {
      this.detailDialog.visible = false
    },
    /**
     * @function 打开修改储值卡弹窗
     */
    target(dialogStatus, data) {
      // if (data.cardType === '3') {
      //   this.editDialog.visible = true
      //   this.$refs.editDialog.getDataQuery(data.holdId)
      // }
      if (dialogStatus) {
        if (data.cardType === '3' || data.cardType === '1') {
          this.$refs.editDialog.getDataQuery(data.holdId, data.cardType)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.editDialog.visible = dialogStatus
      })
    },
    /**
     * @function 关闭修改储值卡弹窗
     */
    changeEditDialog() {
      this.editDialog.visible = false
      this.searchEvent()
    },
    // 下载导入模板
    downFile() {
      this.$http.post(apis.templateExport, {}).then((res) => {
        if (res.data.code === 0) {
          this.downloadFile(res.data.rows.url)
        }
      })
    },
    downloadFile(url) {
      const ele = document.createElement('a');
      ele.setAttribute('href', url); //设置下载文件的url地址
      ele.setAttribute('download', 'download');//用于设置下载文件的文件名
      ele.click();
    },
    // 导入
    uploadError(res) {
      console.log(res);
      this.searchEvent()
    },
    uploadSuccess(res) {
      if (res.data.code === 0) {
        this.searchEvent()
      }
    },
    // 导出
    memberPersonInfoExport(url, params) {
      console.log(url, params)
      this.$http.get(url, { params }).then((res) => {
        if (res.data.code === 0) {
          this.downloadFile(res.data.rows.url)
        }
      })
    },
    // 预览图片
    previewImg(url) {
      if (url) {
        this.previewImage.src = url
        this.previewImage.visible = true
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-container {
  margin: 0;
  height: 50px;
  overflow: hidden;
  .head-left-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    .input-wrapper {
      width: 240px;
      position:relative;
    }
    .input-image {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .el-button {
      height: 36px;
      margin-left: 15px;
    }
  }
}
.table-container {
  // .is-disabled {
  //   background-color: none !important;
  //   border: none !important;
  //   color: #439BFF !important;
  // }
 .member-text {
    margin-left: 5px;
    text-align: left;
  }
  .outDateTip {
    color: red;
  }
}
.page-contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.page-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
.text-name {
  font-weight:400;
  color:rgba(21,21,21,1);
  font-size:14px;
  padding-left: 5px;
}
.row-wrap{
  font-size: 14px;
}
.target {
  color: blue;
  cursor: pointer;
}
.bt{
  // border: 1px solid rgba(228, 233, 241, 1);
  // background: #fff;
  padding: 4px 11px;
  margin-right: 10px;
  cursor: pointer;
  font-size:14px;
  // height:28px;
  background:rgba(238,240,244,1);
  border-radius:4px;
  color:rgba(172,174,176,1);
  // height:28px;
  // line-height: 28px;
  border: 1px solid rgba(238,240,244,1);
}
.active{
  font-size:14px;
  color:rgba(68,155,255,1);
  background:rgba(68,155,255,0.3);
  border-radius:4px;
  border:1px solid rgba(68,155,255,1);
}
.sleepTime{
  // margin-left: 5px;
  .sleep-text {
    font-size:14px;
    color:rgba(21,21,21,1);
    padding-left: 20px;
  }
  div{
    display: inline-block;
  }
}
.inpu{
  display: inline-block;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
<style>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>
